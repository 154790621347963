import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import {Helmet} from 'react-helmet';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/gatsby';
import {useContactPage} from '../hooks/queries';
import {
  Button,
  TextInput,
  TextArea,
  SubmitButton,
  LoadingIndicator,
} from '../components';
import {displayRichText, sleep} from '../utils';
import ContactSchema from '../schemas/ContactSchema';
import Api from '../lib/api';
import Layout from '../components/layout';
import '../styles/pages/contact.scss';
import RouterPaths from '../values/RouterPaths';

const ContactPage = ({location}) => {
  const {t} = useTranslation();
  const content = useContactPage();
  const {
    title,
    heading,
    subheading,
    successSubheading,
    successBody,
    failureSubheading,
  } = content;
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(0);
  const maxCharacters = 500;
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      inquiry: location?.state?.inquiry || 'commercial',
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      message: '',
      inconspicuousInput: '',
    },
    validationSchema: ContactSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        setLoading(true);
        await sleep(1000);
        await Api.contactSubmit(values);
        setFormState(1);
        setLoading(false);
        resetForm();
      } catch (e) {
        setLoading(false);
        if (e.state >= 500) {
          setFormState(2);
          Sentry.captureException(e);
        }
      } finally {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
  });

  const handleFleetClick = () => {
    formik.setFieldValue('inquiry', 'commercial');
  };

  const handleHouseholdClick = () => {
    formik.setFieldValue('inquiry', 'household');
  };

  const handleReturn = () => {
    navigate(RouterPaths.COMMERCIAL);
  };

  const handleReset = () => {
    setFormState(0);
  };

  const handleChange = e => {
    let changeHandled = false;
    if (e.target.name === 'message') {
      if (e.target.value.length >= maxCharacters) {
        formik.setFieldValue(
          e.target.name,
          e.target.value.slice(0, maxCharacters),
        );
        changeHandled = true;
      }
    }
    if (!changeHandled) {
      formik.setFieldValue(e.target.name, e.target.value);
    }
  };
  const {inquiry, firstName, lastName, email, company, message} = formik.values;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="contact w-full flex flex-col items-center justify-center">
        <div className="contact__title text-center">
          <h1 className="desktop:text-6xl text-5xl font-semibold">{heading}</h1>
          <p className="text-xl font-semibold">{subheading}</p>
        </div>
        {formState === 0 && (
          <form
            className="contact__form"
            onChange={handleChange}
            onSubmit={formik.handleSubmit}>
            <div className="contact__form__inquiry w-full flex flex-col">
              <p className="text-lg font-semibold">{t('input:inquiry')}</p>
              <div className="w-full flex flex-col tablet:flex-row gap-2">
                <Button
                  id="fleet-button"
                  label={t('contact:buttons.commercial')}
                  classes={`w-full tablet:w-1/2 ${
                    inquiry === 'commercial' ? '' : 'button--light'
                  }`}
                  handleClick={handleFleetClick}
                />
                <Button
                  id="household-button"
                  label={t('contact:buttons.household')}
                  classes={`w-full tablet:w-1/2 ${
                    inquiry === 'household' ? '' : 'button--light'
                  }`}
                  handleClick={handleHouseholdClick}
                />
              </div>
            </div>
            <div>
              <input
                name="inconspicuousInput"
                className="invisible w-0 h-0"
                type="text"
                tabIndex="-1"
                aria-hidden="true"
                autoComplete="off"
              />
              <TextInput
                id="contact-firstName"
                name="firstName"
                label={t('input:firstName')}
                value={firstName}
                error={formik.errors.firstName}
                containerClasses="text-input-container w-full"
              />
              <TextInput
                id="contact-lastName"
                name="lastName"
                label={t('input:lastName')}
                value={lastName}
                error={formik.errors.lastName}
                containerClasses="text-input-container w-full"
              />
              <TextInput
                id="contact-email"
                name="email"
                placeholder="example@elselabs.io"
                label={t('input:email')}
                value={email}
                error={formik.errors.email}
                containerClasses="text-input-container w-full"
              />
              {inquiry === 'commercial' && (
                <TextInput
                  id="contact-company"
                  name="company"
                  label={t('input:company')}
                  value={company}
                  error={formik.errors.company}
                  containerClasses="text-input-container w-full"
                />
              )}
              <TextArea
                id="contact-message"
                name="message"
                placeholder="Tell us about what you are inquiring about here."
                multiline
                numberOfLines={4}
                maxCharacters={maxCharacters}
                label={t('input:message')}
                value={message}
                error={formik.errors.firstName}
                containerClasses="text-input-container w-full"
              />
              <div className="w-full flex justify-center items-center">
                <SubmitButton
                  id="contact-submit"
                  label="Submit"
                  useIcon={!!loading}
                  icon={loading ? <LoadingIndicator classes="ml-2" /> : null}
                  disabled={
                    firstName.length === 0 ||
                    lastName.length === 0 ||
                    email.length === 0 ||
                    (company.length === 0 && inquiry === 'commercial') ||
                    message.length === 0
                  }
                />
              </div>
            </div>
          </form>
        )}
        {formState !== 0 && (
          <div className="contact__form text-center">
            {formState === 1 && (
              <div className="contact__form--success">
                <h2 className="text-3xl">{successSubheading}</h2>
                {displayRichText(successBody.raw)}
                <div className="contact__form__buttons flex flex-col items-center">
                  <Button
                    id="another-message"
                    label={t('input:sendAnother')}
                    handleClick={handleReset}
                  />
                  <Button
                    id="return-button"
                    label={t('input:return')}
                    classes="button--light flex-none"
                    handleClick={handleReturn}
                  />
                </div>
              </div>
            )}
            {formState === 2 && (
              <div className="contact__form--failure items-center">
                <h2 className="text-3xl">{failureSubheading}</h2>
                <Button
                  id="contact-try-again"
                  label={t('errors:forms.resubmitButton')}
                  handleClick={handleReset}
                  classes="button--error"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

ContactPage.propTypes = {
  location: PropTypes.object,
};

ContactPage.defaultProps = {
  location: {},
};

export default ContactPage;
