import * as Yup from 'yup';

const messageValidation = Yup.string()
  .min(2, 'Must be longer than 2 characters')
  .max(500, 'Must be less than 500 characters')
  .required('Required');

const ContactSchema = Yup.object().shape({
  inquiry: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .email('Please provide a valid email')
    .required('Required'),
  company: Yup.string().when('inquiry', {
    is: 'commercial',
    then: Yup.string()
      .min(1, 'Must be longer than 2 characters')
      .required('Required'),
  }),
  message: messageValidation,
});

export default ContactSchema;
